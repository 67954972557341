<template>
  <div class="list-info" id="watermark">
    <div class="add-box"
         @mousedown="down"
         @touchstart="down"
         @mousemove="move"
         @touchmove="move"
         @mouseup="end"
         @touchend="end"
         draggable="true"
         ref="fu"
         @click="addres" v-show="!control.communityLabelCascader&&!control.moreShow">
      <div class="add-img">
        <img class="add-icon" :src="require('@/assets/img/add.png')" alt="">
        <div class="text">新增、添加</div>
      </div>
    </div>
    <div class="search-top">
      <img :src="require('@/assets/img/search.png')" class="search-icon" />
      <van-field  v-model="dataForm.searchValue" type="search" value="搜索" placeholder="请输入姓名、证件号搜索" class="search-input1"
                  @keydown="onSearch($event)"/>
      <img :src="require('@/assets/img/clear.png')" class="search-clear" @click="onCancels">
    </div>
    <van-popup v-model="control.liveWhichCascader" position="bottom" @click-overlay="overlay">
      <!--      <van-cascader-->
      <!--          title="请选择"-->
      <!--          value="value"-->
      <!--          :field-names="{ text: 'label', value: 'value', children: 'children' }"-->
      <!--          :options="selectList.liveWhichTree"-->
      <!--          active-color="#1989fa"-->
      <!--          @close="liveWhichClose"-->
      <!--          @change="liveWhichFinish"-->
      <!--      />-->
      <house-select @houseConfirm="closeHouseSelect"></house-select>
    </van-popup>
    <!--    <van-popup v-model="control.gridShow" position="bottom"  @click-overlay="overlay">-->
    <!--      <grid-select v-show="control.gridShow" :list="selectList.gridsList" @selectLabel="selectGrid" ref="gridSelect"></grid-select>-->

    <!--    </van-popup>-->
    <van-popup v-model="control.showRegistryType" position="bottom"  @click-overlay="overlay">
      <van-picker
          title="请选择"
          show-toolbar
          :columns="selectList.registryTypeList"
          value-key="label"
          @confirm="onConfirmReg"
          @cancel="onCancelReg"
      />
    </van-popup>
    <van-popup v-model="control.area" position="bottom"  @click-overlay="overlay">
      <van-picker
          title="请选择"
          show-toolbar
          :columns="areaList"
          value-key="label"
          @confirm="onConfirm"
          @cancel="onCancel"
      />
    </van-popup>
    <!-- <div class="search"> -->
    <div>

      <!--      <grid-select v-show="control.gridShow" :list="selectList.gridsList" @selectLabel="selectGrid" ref="gridSelect"></grid-select>-->
<!--      <res-label v-show="control.communityLabelCascader" :list="selectList.userCommunityLabelTree" @selectLabel="selectLabel" ref="resLabel"></res-label>-->

<!--      <select-more v-show="control.moreShow" :community="community" @selectMore="selectMore" ref="selectMore"></select-more>-->

      <div class="dateBar" style="height: 2.6rem;" v-if='control.showDate'>
        <van-row>
          <van-col span="6">出生日期：</van-col>
          <van-col span="6" @click="control.beginBirthday = !control.beginBirthday">
            <span>{{ dataForm.beginBirthday == '' ? '最早' : dataForm.beginBirthday }}</span></van-col>
          <van-col span="1"><span>-</span></van-col>
          <van-col span="6" @click="control.endBirthday = !control.endBirthday">
            <span>{{ dataForm.endBirthday == '' ? '至今' : dataForm.endBirthday }}</span></van-col>
          <van-col span="2" @click="birthdayReset"><span>重置</span></van-col>
          <van-col span="3" @click="birthdayTimeclick"><span>确定</span></van-col>
        </van-row>
        <van-row>
          <van-col span="6">迁入日期：</van-col>
          <van-col span="6" @click="control.beginMoveInDate = !control.beginMoveInDate ">
            <span>{{ dataForm.beginMoveInDate == '' ? '最早' : dataForm.beginMoveInDate }}</span></van-col>
          <van-col span="1"><span>-</span></van-col>
          <van-col span="6" @click="control.endMoveInDate = !control.endMoveInDate ">
            <span>{{ dataForm.endMoveInDate == '' ? '至今' : dataForm.endMoveInDate }}</span></van-col>
          <van-col span="2" @click="moveInReset"><span>重置</span></van-col>
          <van-col span="3" @click="moveIntimeclick"><span>确定</span></van-col>
        </van-row>
      </div>
      <van-popup v-model="control.beginBirthday" position="bottom" >
        <van-datetime-picker @cancel="control.beginBirthday = false" @confirm="beginBirthdayChange" type="date" title="选择年月日" :min-date="defaultDate" :max-date="new Date()"/>
      </van-popup>
      <van-popup v-model="control.endBirthday" position="bottom" >
        <van-datetime-picker @cancel="control.endBirthday = false" @confirm="endBirthdayChange" type="date" title="选择年月日" :min-date="defaultDate" :max-date="new Date()"/>
      </van-popup>
      <van-popup v-model="control.beginMoveInDate" position="bottom" >
        <van-datetime-picker @cancel="control.beginMoveInDate = false" @confirm="beginMoveInDateChange" type="date" title="选择年月日" :min-date="defaultDate" :max-date="new Date()"/>
      </van-popup>
      <van-popup v-model="control.endMoveInDate" position="bottom" >
        <van-datetime-picker @cancel="control.endMoveInDate = false" @confirm="endMoveInDateChange" type="date" title="选择年月日" :min-date="defaultDate" :max-date="new Date()"/>
      </van-popup>
    </div>
    <!--    <van-pull-refresh v-model="control.pullLoading" @refresh="onPullDownRefresh">-->
    <div class="cont" v-show="!control.communityLabelCascader&&!control.moreShow&&!control.gridShow">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" v-if="userResList.length > 0" offset="10">
        <div class="list-item" v-for="item in userResList" :key="item.id">
          <div class="title">
            <div class="title-left" :style="{'color': item.subareaStr ? '' : '#E9564B'}">
              {{item.subareaStr || ''}}:{{item.houseFullName||'未入住'}}
            </div>
            <div class="title-right">
              <template v-if="item.fgQuarter==1"><button :class="`title-registry`+item.fgQuarter ">{{item.fgYear?item.fgYear+'年第'+'一季度' : '未知'}}</button></template>
              <template v-if="item.fgQuarter==2"><button :class="`title-registry`+item.fgQuarter">{{item.fgYear?item.fgYear+'年第'+'二季度' : '未知'}}</button></template>
              <template v-if="item.fgQuarter==3"><button :class="`title-registry`+item.fgQuarter">{{item.fgYear?item.fgYear+'年第'+'三季度' : '未知'}}</button></template>
              <template v-if="item.fgQuarter==4"><button :class="`title-registry`+item.fgQuarter">{{item.fgYear?item.fgYear+'年第'+'四季度' : '未知'}}</button></template>
              <template v-if="!item.fgQuarter"><button class="title-registry">未知</button></template>
            </div>
          </div>
          <div class="content3" @click="info(item.id,item.houseId,item.houseFullName)" :class="radio?'heig' :''" >
            <div class="content-text" :class="radio?'flex' :''" :style="{'margin-left' :radio?'0px' :''}">
              <div class="top-text">
                {{item.userName || '未知'}}
              </div>
              <div class="center-text2" v-if="!radio"><van-tag type="primary" v-for="item in item.labelList"  :key="item" class="top-tag" text-color="#4581F8" color="rgba(78,124,245,0.12)">{{ item }}</van-tag></div>
            </div>
            <div>
              <van-icon name="arrow" color="#666" class="bottom-icon" :style="{'top':radio?'' :'42%'}"/>
              <!--                <div style="height: 0.4rem;"></div>-->
            </div>
            <div class="idNumberBox">
              <div class="center-text2" v-if="radio"><span class="mobile">{{item.mobile || '未知'}}</span><template><img src="@/assets/img/mobile.png" alt="" class="tel" @click.stop="getRealMobile(item.userId)"/></template></div>

              <div class="idNumber" v-if="radio">
                {{item.idNumber || '未知'}}
              </div>
            </div>
            <div class="center-text2 line-bot" v-if="radio"><van-tag type="primary" v-for="item in item.labelList"  :key="item" class="top-tag" text-color="#4581F8" color="rgba(78,124,245,0.12)">{{ item }}</van-tag></div>
          </div>
          <div class="bottom-btns">
            <div  v-if="radio" style="text-align: left;color: #999999">
              {{item.communityStr || '未知'}}
            </div>
            <!--            <button class="btn" @click.stop="moveOut(item.id,item.community)">迁出</button>-->
            <!--            <button class="btn" v-if="item.isPsychosis" @click.stop="cause(item.id, item.name)">肇事</button>-->
            <!--            <button class="btn" v-if="item.isVisit" @click.stop="petition(item.id, item.name)">上访</button>-->
            <!--            <button class="btn" v-if="isVisitOpen" @click.stop="goVisit(item)">走访</button>-->
          </div>
          <!--            v-if="item.notCheckField && item.notCheckField.length > 0"--->
          <!--          <div class="dataCheck" @click.stop="dataCheck(item)" v-if="item.allCheck == 0">-->
          <!--            <img src="@/assets/img/warning.png" alt="" class="warningIcon"/>-->
          <!--            &lt;!&ndash;              <van-icon size=".7rem" name="warning" color="#ffa93a"/>&ndash;&gt;-->
          <!--          </div>-->
        </div>
      </van-list>
      <van-empty description="没有数据哦"  v-if="userResList.length < 1"/>
    </div>
    <!-- </van-pull-refresh> -->
  </div>

</template>

<script>
// import dd from 'gdt-jsapi'
import {formatterDate} from "@/utils/utils";
import {listComRightLabel, getRealMobile} from "@/utils/common";
import topBar from '@/components/topBar/topBar'
import {getImageStream} from '@/utils/index'
import {mobile} from '@/utils/utils'
import {idNumber, desensitization} from '@/utils/utils'
// import ResLabel from './res-label.vue'
// import SelectMore from './select-more'
import addBox from '@/components/addBox'
import HouseSelect from '@/components/houseSelect/index'
import GridSelect from "@/components/gridSelection";
import Vue from "vue";
var that

export default {
  name: "userResIndex",
  components: {
    GridSelect,
    topBar,
    // ResLabel,
    // SelectMore,
    addBox,
    HouseSelect
  },
  data() {
    return {
      DataPageLoading:false,
      community: '',
      userResList: [],
      dataSearchvalue:1,
      dataSearchtype:[
        {value: 1, text: '居民信息'}, {value: 2, text: '地址信息'}
      ],
      finished:false,
      totalNum: 0,
      dataForm: {
        searchValue:'',
        fgType:2,
        page: 0,
        limit: this.$globalData.limit,
        gridId: "",
        orgId: this.$globalData.userInfo.orgId,
        labels: "",
        orgDepth: "",
        buildingIds: "",
        unitId: "",
        houseId: "",

      },
      radio:true,
      loading: false,
      defaultDate: new Date(1900, 0, 1),
      show: {
        registryType: '性质',
        beginBirthday: '最早',
        endBirthday: "至今",
        beginMoveInDate: '最早',
        endMoveInDate: "至今",
        nativePlace: '籍贯',
        gridName: '',
        age: '',
        sex: '',
        haveHouse: '',
        rightIdNumber: '',
        notInspect: ''
      },
      control: {
        showRegistryType: false,
        pullLoading: false,
        liveWhichCascader: false,
        communityLabelCascader: false,
        moreShow: false,
        searchBarHidden: false,
        showDate: false,
        beginBirthday: false,
        endBirthday: false,
        beginMoveInDate: false,
        endMoveInDate: false,
        area: false,
        gridShow: false,
        haveMore: false,
        hasSubmit: false
      },
      activeIndex: true,
      selectList: {
        liveWhichTree: [],
        userCommunityLabelTree: [],
        gridsList: [],
        ageList: [{
          label: '18岁以下',
          value: '0,18',
        },
          {
            label: '18-29',
            value: '18,29',
          },
          {
            label: '30-39',
            value: '30,39',
          },
          {
            label: '40-49',
            value: '40,49',
          },
          {
            label: '50-59',
            value: '50,59',
          },
          {
            label: '60-69',
            value: '60,69',
          },
          {
            label: '70-79',
            value: '70,79',
          },
          {
            label: '80岁及以上',
            value: '80,0',
          },
        ],
        sexList: [{
          label: '男',
          value: '1',
        },
          {
            label: '女',
            value: '2',
          },
          {
            label: '所有',
            value: '',
          }],
        registryTypeList: [],
        haveHouseList: [{
          label: '所有',
          value: '1',
        },
          {
            label: '未入住',
            value: '0',
          }],
        rightIdNumberList: [{
          label: '错误身份证',
          value: "0",
        }, {
          label: '正确身份证',
          value: "1",
        }]
      },
      areaList:
          [{value: "11", label: '北京'}, {value: "12", label: '天津', status: 0}, {value: '13', label: '河北'}, {
            value: '14',
            label: '山西'
          }, {value: '15', label: '内蒙古'},
            {value: '21', label: '辽宁'}, {value: '22', label: '吉林'}, {value: '23', label: '黑龙江'}, {
            value: '31',
            label: '上海'
          }, {value: '32', label: '江苏'}, {value: '33', label: '浙江'},
            {value: '34', label: '安徽'}, {value: '35', label: '福建'}, {value: '36', label: '江西'}, {
            value: '37',
            label: '山东'
          }, {value: '41', label: '河南'}, {value: '42', label: '湖北'},
            {value: '43', label: '湖南'}, {value: '44', label: '广东'}, {value: '45', label: '广西'}, {
            value: '46',
            label: '海南'
          }, {value: '50', label: '重庆'}, {value: '51', label: '四川'},
            {value: '52', label: '贵州'}, {value: '53', label: '云南'}, {value: '54', label: '西藏'}, {
            value: '61',
            label: '陕西'
          }, {value: '62', label: '甘肃'}, {value: '63', label: '青海'},
            {value: '64', label: '宁夏'}, {value: '65', label: '新疆'}, {value: '71', label: '台湾'}, {
            value: '81',
            label: '香港'
          }, {value: '82', label: '澳门'}, {value: '91', label: '国外'}],
      Checked:require('@/assets/img/radio-select-checked.png'),
      noChecked:require('@/assets/img/radio-select.png'),
      isVisitOpen: false,
      visitAppInfo: {},
      flags: false, //控制使用
      position: {
        x: 0,
        y: 0,
      },
      nx: "",
      ny: "",
      dx: "",
      dy: "",
      xPum: "",
      yPum: "",
    }
  },
  created() {
    this.getGridList()
    this.control.gridShow = false
    this.$watermark.set(this.$globalData.userInfo.userName, this.$globalData.userInfo.mobile)
    that = this
    this.userName = this.$userName
    this.dataForm.isNoBasicsCheck = this.$route.query.isNoBasicsCheck || 0
    this.orgName = this.$orgName
    this.dataForm.notInspect = this.$globalData.notInspect
    this.getAppInfo()
    this.init()
  },
  activated () {

    if (this.$route.query.searchInfo) {
      let globalSearchValue = this.$route.query
      globalSearchValue.searchInfo = JSON.parse(decodeURIComponent(globalSearchValue.searchInfo))
      if (globalSearchValue.searchVal) {
        globalSearchValue.searchVal = JSON.parse(decodeURIComponent(globalSearchValue.searchVal))
      }
      this.dataForm = {
        ...this.dataForm,
        ...globalSearchValue.searchInfo,
      }
      if (globalSearchValue.searchInfo.labels || globalSearchValue.searchVal.labels) {
        this.dataForm.labelCondition = 2
      }

      if (globalSearchValue.searchVal) {
        this.dataForm[globalSearchValue.searchVal.type] = globalSearchValue.searchVal.value
        if (globalSearchValue.searchVal.labels || globalSearchValue.searchVal.type == 'labels') {
          this.$refs.resLabel.selectList = [parseInt(globalSearchValue.searchVal.value || globalSearchValue.searchVal.labels)]
        }
        if (globalSearchValue.searchVal.age || globalSearchValue.searchVal.type == 'age') {
          let ageList = (globalSearchValue.searchVal.value || globalSearchValue.searchVal.age).split('^')
          this.dataForm['beginAge'] = ageList[0]
          this.dataForm['endAge'] = ageList[1]
          this.$refs.selectMore.dataForm.beginAge = ageList[0]
          this.$refs.selectMore.dataForm.endAge = ageList[1]
        }
        if (globalSearchValue.searchVal.check || globalSearchValue.searchVal.type == 'allCheck') {
          this.dataForm.isNotChecked = globalSearchValue.searchVal.value
        }
      }
      this.dataForm.page = 0;
      this.dataList = [];
      this.getPreferenceOrg()
    }
  },
  methods: {
    onSearch(event) {
      if(event.keyCode === 13) {
        this.dataForm.page = 0;
        this.userResList = [];
        this.getDataList();
      }
    },
    onCancels(){
      this.dataForm.searchValue='';
      this.dataForm.page = 0;
      this.userResList = [];
      this.getDataList();
    },
    getGridList () {

      this.$http({
        url: this.$http.adornUrl(`/wxapp/grid/info/gridAndMini`),
        method: 'get',
        params: this.$http.adornParams({
          community: this.community || this.$globalData.userInfo.orgId
        })
      }).then(({data}) => {
        if (data.code == 0) {
          this.selectList.gridsList = data.treeVOS
        }
      })

    },
    selectGrid (item) {
      if(item && item.length) {
        this.dataForm.gridId = ''
        this.dataForm.miniGridId = ''
        let checkedItem = item[0]
        if(checkedItem.depth == 1) {
          this.dataForm.gridId = checkedItem.value
        }else {
          this.dataForm.miniGridId = checkedItem.value
        }
        this.dataForm.orgId=checkedItem.parameters.communityId
        this.show.gridName = checkedItem.label
      }
      this.control.gridShow = false
      this.onPullDownRefresh()
    },
    cancelGrid() {
      this.dataForm.gridId = ''
      this.dataForm.miniGridId = ''
      this.gridValue = '所属网格'
      this.page = 0;
      this.dataList = [];
      this.getDataList();
      // this.getDataPageNum();
      this.gridShow = false
    },
    // gridsFn() {
    //   this.control.gridShow = true
    //   // this.show.gridName = n.label
    //   // this.dataForm.gridId = n.value
    // },
    searchValue(value){
      if(value==2){
        this.dataForm.searchValueType=2
      }
      else{
        this.dataForm.searchValueType=1
      }
    },
    getRealMobile(id) {
      getRealMobile(id,function(data) {
        window.location.href = `tel:${data.mobile}`
      })
    },
    getRegistryType () {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/sys/dict/virtual/registryType`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({data}) => {
        if (data.code == 0) {
          data.registryTypes.map((type) => {
            type.value = type.value + ""
          })
          this.selectList.registryTypeList = data.registryTypes
        }
      })
    },
    dataCheck (item) {
      let msg =  item.checkList.filter(item => item != '').map((item, index) => {
        return `<p>${index + 1}.${item}</p>`
      }).join('')
      this.$dialog.confirm({
        title: '错误信息',
        center: true,
        message: msg,
        showCancelButton: true,
        confirmButtonText: '去处理',
        cancelButtonText: '关闭',
        confirmButtonColor: '#ed4747'
      }).then(() => {
        this.$router.push({path: '/userRes-dataCheck', query: {data: JSON.stringify(item.notCheckField), userId: item.id, resId: item.resId}})
      })
    },
    Desensitization (value) {
      return desensitization(value)
    },
    getImg (path) {
      return  getImageStream(path)
    },
    goVisit(item) {
      let url = this.visitAppInfo.pageUrlH5.split('=')[0] + '=visit-add'
      let houseId = null
      if (item.comHouseList && JSON.stringify(item.comHouseList) != '{}') {
        houseId =  Number(item.comHouseList[item.communityName][1].id)
      }
      this.$router.push({
        path: '/appSSO',
        query: {
          userId: item.id,
          userName: item.name,
          houseId: houseId,
          appId: this.visitAppInfo.appId,
          appCode: this.visitAppInfo.appCode,
          appName: this.visitAppInfo.appName,
          url: url,
          isToInfo: 1,
        }
      })
      // let houseId = null
      // if (item.houseList) {
      //   houseId =  Number(item.houseList[1].id)
      // }
      // this.$router.push({path: '/visit-add', query: {houseId: houseId, userId: item.id, userName: item.name, fromPage: 'userRes'}})
    },
    init() {
      listComRightLabel(function (success) {
        that.selectList.userCommunityLabelTree = success
      })
      this.getRegistryType()
      this.getPreferenceOrg()
    },
    getAppInfo() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/application/geMyAppInfoByCode'),
        method: 'GET',
        params: this.$http.adornParams({
          codes: 'sjgl_sgzf_zfjl',
          orgId: this.$orgId
        })
      }).then(({data}) => {
        if(data && data.code === 0) {
          data.list.forEach(item => {
            if(item.appCode === 'sjgl_sgzf_zfjl') {
              this.isVisitOpen = true
              this.visitAppInfo = item
            }
          })
        }
      })
    },
    getPreferenceOrg() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/user/preferenceOrgId'),
        method: 'GET',
        params: this.$http.adornParams({
        })
      }).then(({data}) => {
        if(data && data.code === 0) {
          if (data.preferenceOrgId) {
            this.dataForm.orgId = data.preferenceOrgId
          }
          this.getDataList();
          // this.getDataPageNum()
        }
      }).catch(err=>{
        this.getDataList();
        // this.getDataPageNum()
      })
    },
    // getDataPageNum(){
    //   this.DataPageLoading = false
    //   this.$http({
    //     url: this.$http.adornUrl('/wxapp/user/res/list/count'),
    //     method: 'post',
    //     params: this.$http.adornParams({
    //       ...that.dataForm,
    //       num: this.dataForm.page == 1 ? '1000' : this.totalNum
    //     }),
    //   }).then(({data}) => {
    //     if (data && data.code === 0) {
    //       this.totalNum = data.page.totalCount
    //     } else {
    //       this.userResList = []
    //       this.totalNum = 0
    //       that.$toast.fail(data.msg)
    //     }
    //     this.DataPageLoading = true
    //   })
    // },
    getDataList() {
      this.DataPageLoading = false
      this.dataForm.page++
      if (!that.control.hasSubmit && that.dataForm.page == 1) {
        this.$toast.loading({
          duration: 0,
          message: '加载中...',
          forbidClick: true,
        });
      }
      if (this.dataForm.notInspect !== '1') {
        this.dataForm.notInspect = '0'
      }
      this.$http({
        url: this.$http.adornUrl(`/wxapp/fAndG/fguserrelationship/list`),
        method: 'post',
        params: this.$http.adornParams(that.dataForm)
      }).then(({data}) => {
        that.loading = false
        this.control.pullLoading = false
        if (!that.control.hasSubmit) {
          that.$toast.clear();
        }
        if (data.code == 0) {
          if (this.userResList.length == data.page.totalCount) {
            this.finished = true;
          }
          this.totalNum = data.page.totalCount
          let datas = data.page.list;
            for (let i in datas) {
              let data = datas[i];
              if (data.labelShorts) {
                //
                data["isPsychosis"] = data.labelShorts.search('精') == -1 ? false : true;
                data["isVisit"] = data.labelShorts.search('访') == -1 ? false : true;
                data['labelList'] = data.labelShorts.split(',')
              }
              let date = formatterDate(new Date(data.createTime));
              data.createTime = date
            }
            let list = that.dataForm.page == 1 ? datas : that.userResList.concat(datas);
            let haveMore = datas.length !== this.totalNum ? false : true;
            that.control.haveMore = haveMore
            that.activeIndex = false
            that.userResList = list


        } else {
          that.$toast.fail(data.msg)
          that.activeIndex = true
          that.control.haveMore = false;
        }
        this.DataPageLoading = true
        that.control.hasSubmit = false;
      })
    },
    // getDataList() {
    //   this.dataForm.page++
    //   console.log(this.dataForm.page)
    //   if (!that.control.hasSubmit && that.dataForm.page == 1) {
    //     this.$toast.loading({
    //       duration: 0,
    //       message: '加载中...',
    //       forbidClick: true,
    //     });
    //   }
    //   if (this.dataForm.notInspect !== '1') {
    //     this.dataForm.notInspect = '0'
    //   }
    //   this.$http({
    //     url: this.$http.adornUrl(`/wxapp/user/res/list`),
    //     method: 'post',
    //     params: this.$http.adornParams({
    //       ...that.dataForm,
    //       num: this.dataForm.page == 1 ? '1000' : this.totalNum
    //     }),
    //
    //   }).then(({data}) => {
    //     that.loading = false
    //     this.control.pullLoading = false
    //     if (!that.control.hasSubmit) {
    //       that.$toast.clear();
    //     }
    //     if (data.code == 0) {
    //
    //       // this.totalNum = data.page.totalCount
    //       let datas = data.page.list;
    //
    //       if (datas.length > 0) {
    //         for (let i in datas) {
    //           let data = datas[i];
    //           if (data.labelShorts) {
    //             //
    //             data["isPsychosis"] = data.labelShorts.search('精') == -1 ? false : true;
    //             data["isVisit"] = data.labelShorts.search('访') == -1 ? false : true;
    //             data['labelList'] = data.labelShorts.split(',')
    //           }
    //           let date = formatterDate(new Date(data.createTime));
    //           data.createTime = date
    //         }
    //         let list = that.dataForm.page == 1 ? datas : that.userResList.concat(datas);
    //         if(data.page.currPage==data.page.totalPage){
    //           that.control.haveMore = true
    //         }
    //         // let haveMore = datas.length !== this.totalNum ? false : true;
    //         // console.log(haveMore)
    //         // that.control.haveMore = haveMore
    //         that.activeIndex = false
    //         that.userResList = list
    //
    //       }
    //       else if (datas == '' && (that.dataForm.page != 2&&that.dataForm.page != 1)) {
    //         that.activeIndex = true
    //         that.control.haveMore = true
    //       }
    //       else {
    //         console.log('第一页为空')
    //         that.control.haveMore = true
    //         that.activeIndex = true
    //         that.userResList = []
    //       }
    //     } else {
    //       that.$toast.fail(data.msg)
    //       that.activeIndex = true
    //       that.control.haveMore = false;
    //     }
    //     that.control.hasSubmit = false;
    //   })
    // },
    searchConfirm(val) {
      this.onPullDownRefresh()
    },
    searchCancel(val) {
      this.dataForm.searchValue = ''
      this.onPullDownRefresh()
    },
    beginBirthdayChange(value) {
      let d = new Date(this.dataForm.endBirthday)
      if (this.dataForm.endBirthday != '' && d.getTime(d) < value.getTime(value)) {
        return this.$toast.fail('请选择正确的范围')
      }
      this.dataForm.beginBirthday = formatterDate(value)
      this.control.beginBirthday = false

    },
    endBirthdayChange(value) {
      let d = new Date(this.dataForm.beginBirthday)
      if (this.dataForm.beginBirthday != '' && d.getTime(d) > value.getTime(value)) {
        return this.$toast.fail('请选择正确的范围')
      }
      this.dataForm.endBirthday = formatterDate(value)
      this.control.endBirthday = false
    },
    beginMoveInDateChange(value) {
      let d = new Date(this.dataForm.endMoveInDate)
      if (this.dataForm.endMoveInDate != '' && d.getTime(d) < value.getTime(value)) {
        return this.$toast.fail('请选择正确的范围')
      }
      this.dataForm.beginMoveInDate = formatterDate(value)
      this.control.beginMoveInDate = false
    },
    endMoveInDateChange(value) {
      let d = new Date(this.dataForm.beginMoveInDate)
      if (this.dataForm.beginMoveInDate != '' && d.getTime(d) > value.getTime(value)) {
        return this.$toast.fail('请选择正确的范围')
      }
      this.dataForm.endMoveInDate = formatterDate(value)
      this.control.endMoveInDate = false
    },
    changeHouseSelect (val) {
      this.dataForm = {... this.dataForm, ...val}
    },
    closeHouseSelect (val, val2) {
      this.control.liveWhichCascader = false
      this.dataForm = {... this.dataForm, ...val}
      if (this.$globalData.userInfo.depth == 4 && val2&&val2[0]) {
        this.community = val2[0].value
        this.dataForm.gridId = ''
        this.dataForm.miniGridId = ''
      }
      if(this.$globalData.userInfo.depth == 4 && !val2){
        this.community=''
      }
      this.onPullDownRefresh()
      this.getGridList()
    },
    onConfirmReg(e, index) {
      this.dataForm.registryType = e.value
      this.show.registryType = e.label
      this.onPullDownRefresh()
      this.control.showRegistryType = false
    },
    onCancelReg() {
      this.show.registryType = '户籍性质'
      this.dataForm.registryType = ""
      this.onPullDownRefresh()
      this.control.showRegistryType = false
    },
    onConfirm(e, index) {
      this.dataForm.nativePlace = e.value
      this.dataForm.nativePlaceStr=e.label
      this.show.nativePlace = e.label
      this.onPullDownRefresh()
      this.control.area = false
    },
    onCancel() {
      this.show.nativePlace = '居民籍贯'
      this.dataForm.nativePlace = ""
      this.dataForm.nativePlaceStr=""
      this.control.area = false
      this.onPullDownRefresh()
    },
    liveWhichFinish(e) {
      this.dataForm.orgId = this.$globalData.userInfo.orgId;
      this.dataForm.buildingIds = ""
      this.dataForm.unitId = ""
      this.dataForm.houseId = ""
      if (e.selectedOptions[(e.selectedOptions.length - 1)].depth == 0) {

      } else if (e.selectedOptions[(e.selectedOptions.length - 1)].depth == 1) {
        this.dataForm.orgId = e.selectedOptions[(e.selectedOptions.length - 1)].value
      } else if (e.selectedOptions[(e.selectedOptions.length - 1)].depth == 2) {
        this.dataForm.buildingIds = e.selectedOptions[(e.selectedOptions.length - 1)].value
      } else if (e.selectedOptions[(e.selectedOptions.length - 1)].depth == 3) {
        this.dataForm.unitId = e.selectedOptions[(e.selectedOptions.length - 1)].value
      } else if (e.selectedOptions[(e.selectedOptions.length - 1)].depth == 4) {
        this.dataForm.houseId = e.selectedOptions[(e.selectedOptions.length - 1)].value
      }

      // 'show.createUserArray': e.selectedOptions.map((n) => n.label).join('/'),
      this.dataForm.orgId = this.dataForm.orgId
      this.dataForm.buildingIds = this.dataForm.buildingIds
      this.dataForm.unitId = this.dataForm.unitId
      this.dataForm.houseId = this.dataForm.houseId
      // 'show.createUserName': e.selectedOptions[(e.selectedOptions.length - 1)].label
    },
    liveWhichClose(e) {
      this.dataForm.orgId = this.$globalData.userInfo.orgId;
      this.dataForm.buildingIds = ''
      this.dataForm.unitId = ''
      this.dataForm.houseId = ''
      this.control.liveWhichCascader = false
      this.onPullDownRefresh()
    },
    //标签信息改变
    selectLabel (e) {
      const {selectList, labelCondition} = e
      if(labelCondition==4){
        const{anyLabel,allLabel,noneLabel}=e
        this.dataForm.labelCondition = labelCondition + ''
        this.dataForm.anyLabel=anyLabel
        this.dataForm.allLabel=allLabel
        this.dataForm.noneLabel=noneLabel
        delete this.dataForm.labels;
        console.log(this.dataForm.anyLabel)
      }
      else {
        delete this.dataForm.anyLabel;
        delete this.dataForm.allLabel;
        delete this.dataForm.noneLabel;
        this.dataForm.labels = selectList
        this.dataForm.labelCondition = labelCondition + ''
        console.log(this.dataForm.labels)
      }
      this.control.communityLabelCascader = false
      this.onPullDownRefresh()
    },
    selectMore (e) {
      this.dataForm = {
        ...this.dataForm,
        ...e,
      }
      this.control.moreShow = false
      this.onPullDownRefresh()
    },
    communityLabelCascaderChange(e) {
      that.dataForm.labels = ""
      that.dataForm.labels = e.selectedOptions[(e.selectedOptions.length - 1)].value
    },
    communityLabelCascaderClose(e) {
      that.dataForm.labels = ""
      this.control.communityLabelCascader = false
      this.onPullDownRefresh()
    },
    /**
     * 页面相关事件处理函数--用户下拉动作
     */
    onPullDownRefresh(e) {
      this.control.haveMore=false
      this.dataForm.page = 0;
      this.getDataList()
      // this.getDataPageNum()
    },
    //所有级联的关闭
    overlay() {
      this.onPullDownRefresh()
    },
    //出生日期重置按钮点击
    birthdayReset: function (e) {
      that.dataForm.beginBirthday = '';
      that.dataForm.endBirthday = '';
    },
    //出生日期时间筛选中点击确定
    birthdayTimeclick: function (e) {
      this.onPullDownRefresh()
    },
    //迁入时间重置按钮点击
    moveInReset: function (e) {
      that.dataForm.beginMoveInDate = '';
      that.dataForm.endMoveInDate = '';
    },
    //嵌入时间筛选中点击确定
    moveIntimeclick: function (e) {
      that.onPullDownRefresh()
    },
    //选择网格
    gridsFn(val) {
      this.show.gridName = val.label
      this.dataForm.gridId = val.value
    },
    ageFn(n) {
      this.show.age = n.label
      // this.dataForm.gridId = val.value
      const selected = n.value
      let ageArray = selected.split(",")
      this.dataForm.beginAge = ageArray[0];
      this.dataForm.endAge = ageArray[ageArray.length - 1];
    },
    sexFn(n) {
      this.show.sex = n.label
      this.dataForm.sex = n.value

    },
    registryFn(n) {
      this.show.registryType = n.label
      this.dataForm.registryType = n.value
    },
    haveHouseFn(n) {
      this.show.haveHouse = n.label
      this.dataForm.haveHouse = n.value
    },
    rightIdNumberFn(n) {
      this.show.rightIdNumber = n.label
      this.dataForm.rightIdNumber = n.value
    },
    moreClose() {

    },
    moreCancel() {
      //重置
      this.dataForm.gridId = ''
      this.dataForm.age = ''
      this.dataForm.sex = ''
      this.dataForm.registryType = ''
      this.dataForm.haveHouse = ''
      this.dataForm.rightIdNumber = ''
      this.show.gridName = ''
      this.show.age = ''
      this.show.sex = ''
      this.show.registryType = ''
      this.show.haveHouse = ''
      this.show.rightIdNumber = ''
      this.control.moreShow = false
      this.onPullDownRefresh()
    },
    moreConfirm() {
      this.control.moreShow = false
      this.onPullDownRefresh()
    },
    // petition (id, name) {
    //   this.$router.push({path: '/petition-info', query: {userId: id, userName: name}})
    // },
    // cause (id, name) {
    //   this.$router.push({path: '/cause-info', query: {userId: id, userName: name}})
    // },
    info(id,houseId,houseName) {
      sessionStorage.setItem('changeType', 1)
      this.$router.push({path: '/zoon-info', query: {userId: id,houseId:houseId,houseName:houseName}})
    },
    getImageStream(e) {
      return getImageStream(e)
    },
    //打电话
    callPhone(mobile) {
      callPhone({
        phoneNum: mobile,//电话号码
      }).then(res => {
        console.log(res)
      }).catch(err => {})
    },
    idNumber(e) {
      return idNumber(e)
    },
    mobile(e) {
      return mobile(e)
    },
    /**
     * 页面上拉触底事件的处理函数
     */
    onReachBottom: function () {
      if (this.control.haveMore) {
        this.dataForm.page++;
        this.getDataList();
      }
    },
    // 新增居民
    addres() {
      this.$router.push({path: '/zoon-info'})
    },
    //迁出
    moveOut(e,community) {
      this.$dialog.confirm({
        message: '确认迁出？',
      }).then(() => {
        //户籍性质
        this.$http({
          url: this.$http.adornUrl(`/wxapp/user/res/moveOutCommunity`),
          method: 'get',
          params: this.$http.adornParams({
            id: parseInt(e),
            community: this.$globalData.userInfo.depth == 4 ? community : that.$globalData.userInfo.orgId
          })
        }).then(({data}) => {
          if (data.code == 0) {
            that.$toast.success({
              message:'迁出成功',
              onOpened:()=>{
                setTimeout(()=>{
                  that.onPullDownRefresh();
                },1500)
              }
            })
          } else {
            that.$toast.fail(data.msg)
          }
        })
      })
    },
    down(event) {
      this.flags = true;
      var touch;
      if (event.touches) {
        touch = event.touches[0];
      } else {
        touch = event;
      }
      this.position.x = touch.clientX;
      this.position.y = touch.clientY;
      this.dx = this.$refs.fu.offsetLeft;
      this.dy = this.$refs.fu.offsetTop;
    },
    move(event) {
      if (this.flags) {
        var touch;
        if (event.touches) {
          touch = event.touches[0];
        } else {
          touch = event;
        }
        this.nx = touch.clientX - this.position.x;
        this.ny = touch.clientY - this.position.y;
        this.xPum = this.dx + this.nx;
        this.yPum = this.dy + this.ny;
        let width = window.innerWidth - this.$refs.fu.offsetWidth; //屏幕宽度减去自身控件宽度
        let height = window.innerHeight - this.$refs.fu.offsetHeight; //屏幕高度减去自身控件高度
        this.xPum < 0 && (this.xPum = 0);
        this.yPum < 0 && (this.yPum = 0);
        this.xPum > width && (this.xPum = width);
        this.yPum > height && (this.yPum = height);
        this.$refs.fu.style.left = this.xPum + "px";
        this.$refs.fu.style.top = this.yPum + "px";
        //阻止页面的滑动默认事件
        document.addEventListener("touchmove",function (event) {
          event.preventDefault();
        },{passive:false});
      }
    },
    //鼠标释放时候的函数
    end() {
      this.flags = false;
    },
  }
}

</script>

<style lang="scss" scoped>
.cont{
  margin-top: 20px;
}
.filterBar {
  padding: 0 10px 0 0;
}
.van-grid-item__content {
  padding: 5px 0 !important;
}
.content-text {
  height: 100px !important;
  width: 120px !important;
  line-height: 100px;
  .tel {
    width: 40px !important;
    height: 40px !important;
    margin-bottom: 5px;
  }
}
.content-img {
  width: 120px !important;
  height: 120px !important;
  img {
    width: 120px !important;
    height: 120px !important;
  }
}
.van-popup {
  .van-tag {
    text-align: center;
    display: block;
    width: 190px;
    height: 70px;
    line-height: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 10px;
  }
}

.imgg {
  width: 1.62rem;
  height: 1.62rem;
}

.buttons {
  font-size: 10px;
  border-radius: 0.12rem;
}

.tag-view {
  display: flex;
}

.wux {
  margin-left: 0.15rem;
}
.userFontSize{
  font-size: 30px;
}
.mini-font-size{
  font-size: 25px;
}
.dataCheck {
  position: absolute;
  right: 95px;
  // top: 132px;
  top: 44%;
  .warningIcon {
    width: 40px;
    height: 40px;
  }
}
.list-item {
  position: relative;
  .title {
    .title-left {
      //line-height: 106px;
      width: 320px !important;
    }
    .title-right {
      width: 320px !important;
      .tel {
        width: 40px !important;
        height: 40px !important;
        margin-bottom: 5px;
      }
    }
  }
  .content3 {
    display: flex !important;
    flex-direction: row;
    height: auto !important;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    padding: 20px 0 15px 0;
    .content-text {
      .idNumber {
        margin-top: 10px;
      }
      .center-text2 {
        font-size: 28px !important;
        .mobile {
          color: #4581F8;
        }
      }
    }
    .idNumber {
      width: 100%;
      margin: 20px 0;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .idNumberBox{
      .idNumber {
        margin-top: 10px;
      }

      .center-text2 {
        font-size: 28px !important;
        .tel {
          width: 40px !important;
          height: 40px !important;
          margin-left: 60px;
          margin-bottom: 5px;
        }
        .mobile {
          color: #4581F8;
        }
      }
    }
  }
  .heig{
    //display: block !important;
    height: auto !important;
    padding: 24px 0 24px 0;
    .content-text {
      .top-text {
        width: unset !important;
        min-width: 100px;
        // margin-bottom: 20px;
      }
      .center-text2 {
        padding-right: 20px;
        margin-top: unset !important;
      }
    }
  }
  .flex{
    display: flex;
  }
  .top-tag{
    width: 44px !important;
    height: 44px !important;
    line-height: 44px !important;
    background-color: rgba(78, 124, 245, 0.12) !important;
    border-radius: 4px !important;
    margin-right: 10px !important;
    color: #4E7CF5 !important;
    border: none !important;
  }
  .bottom-btns {
    height: 100px;
    line-height: 100px;
    font-size:24px ;
    .btn {
      //flex: 1;
      width: 152px;
      height: 60px;
      line-height: 60px;
      border: 0;
      font-size: 28px;
      color: #fff;
      font-family: PingFangSC-Regular, PingFang SC;
      border-radius: 8px;
      background-color: #4581F8;
      text-align: center;
      margin-right: 26px;
      &:last-child {
        margin: 0;
      }
    }
  }
}
.totalCount {
  text-align: left !important;
  padding-left: 34px !important;
}
.model{
  display: inline-block;
  position: absolute;
  right: 4%;
  img{
    margin-bottom: 2px;
  }
}
.Checked{
  font-size: 18px;
  color: #4E7CF5 !important;
  //color: #999999;
  img {
    width: 26px;
  }
}
.noChecked{
  font-size: 18px;
  color: #999999;
  //color: #4E7CF5 !important;
  img {
    width: 26px;
  }
}
.bottom-icon {
  font-size: 36px !important;
  position: absolute;
  right: 0;
  top: 20%;
  // margin-top: 35px;
  // bottom:40%;
}
.title-registry {
  // width: 165px;
  //height: 53px;
  padding: 8px 20px 10px 20px;
  background: rgba(78, 124, 245, 0.12);
  border-radius: 10px;
  border: none;
  margin-left: 15px;
  font-weight: 600;
  font-size: 26px;
  color: #4E7CF5;
}
.title-registry1 {
  // width: 165px;
  //height: 53px;
  padding: 8px 20px 10px 20px;
  background: rgba(78, 124, 245, 0.12);
  border-radius: 10px;
  border: none;
  margin-left: 15px;
  font-weight: 600;
  font-size: 26px;
  color: #4E7CF5;
}
.title-registry2 {
  // width: 165px;
  //height: 53px;
  padding: 8px 20px 10px 20px;
  background: rgba(233,86,75,0.1);
  border-radius: 10px;
  border: none;
  margin-left: 15px;
  font-weight: 600;
  font-size: 26px;
  color: #E9564B;
}
.title-registry3 {
  // width: 165px;
  //height: 53px;
  padding: 8px 20px 10px 20px;
  background: rgba(43,143,50,0.1);
  border-radius: 10px;
  border: none;
  margin-left: 15px;
  font-weight: 600;
  font-size: 26px;
  color: #2B8F32;
}
.title-registry4 {
  // width: 165px;
  //height: 53px;
  padding: 8px 20px 10px 20px;
  background: rgba(223,137,30,0.1);
  border-radius: 10px;
  border: none;
  margin-left: 15px;
  font-weight: 600;
  font-size: 26px;
  color: #DF891E;
}
.van-col {
  p {
    width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.list-search{
  display: flex;
  ::v-deep.van-ellipsis{
    color: #666666 !important;
    font-size: 24px ;
    padding-top:10px ;
    background-color: rgba(0,0,0,0);
  }
  ::v-deep.van-dropdown-menu__bar{
    box-shadow: none;
  }
}
.search-top {
  position: relative;
  height: 112px;
  padding: 14px 30px;
  .search-input1 {
    width: 100%;
    height: 100%;
    border: none;
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    padding-left: 60px;
    font-size: 26px;
    font-family: 'PingFangSC-Regular';
    font-weight: 400;

    &::placeholder {
      color: #999999;
    }
  }
}
.search-icon{
  position: absolute;
  top: 20px;
  left: 24px;
  width: 32px;
  height: 32px;

}
.search-clear {
  width: 22px;
  height: 22px;
  position: absolute;
  right: 200px;
  top: 40px;
}
</style>
